import classNames from 'classnames';
import BBadge from 'components/common/BBadge';
import BImage from 'components/common/BImage';
import CurrencyText from 'components/common/CurrencyText';
import { useBreakpoint } from 'hooks/useBreakpoints';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { FunctionComponent, useMemo } from 'react';
import styles from 'styles/ExperienceCard.module.css';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import Favourited from 'components/common/Favourited';
import RatingStar from 'components/common/RatingStar';
import { ExperienceSummary } from 'types/experience/experience-summary.type';
import { getExperienceDurationByTime } from 'utils/experience/experienceDuration.util';
import { useFavoriteStore } from 'states/useFavorite.store';
import { useFavorite } from 'hooks/useFavorite';
import { useUserState } from 'states/useUserState';
import { ExperienceSelectAndWishlistEventPayload } from 'types/analytics.type';
import { useRouter } from 'next-translate-routes';
import { usePrevLink } from 'hooks/usePrevLink';
import { analyticsService } from 'services/AnalyticsService';
import { linkService } from 'services/LinkService';
import { useLocale } from 'hooks/useLocale';

interface ExperienceCardProps {
  experience: ExperienceSummary;
  isFirstImagePrioritized?: boolean;
  containerClass?: string;
}

const ExperienceCard: FunctionComponent<ExperienceCardProps> = ({
  containerClass,
  isFirstImagePrioritized = false,
  experience,
}) => {
  const { t } = useTranslation('common');
  const { asPath } = useRouter();
  const { getPrevLink } = usePrevLink();
  const { isMobile } = useBreakpoint();
  const { hour, minute } = getExperienceDurationByTime(experience.duration);
  const { isUserLoggedIn } = useUserState();
  const { getExperienceFavorited } = useFavoriteStore();
  const { locale } = useLocale();

  const analyticsPayload: ExperienceSelectAndWishlistEventPayload = useMemo(
    () => ({
      experienceId: experience.id,
      experienceName: experience.title,
      experienceCategory: experience.type,
      regionTrName: '', // experience.regionTRName,
      asPathOfPreviousPage: getPrevLink(),
    }),
    [experience, asPath]
  );

  const isFavorited =
    isUserLoggedIn &&
    (experience.isFavorited || getExperienceFavorited(experience.id));
  const { isCurrentFavorited, toggleExperienceFavorite } = useFavorite(
    isFavorited,
    undefined,
    analyticsPayload
  );

  const sendAnalytics = () => {
    analyticsService.experienceCardClick(analyticsPayload);
  };

  const linkConstructor = () => {
    return linkService.getExperienceLink(
      experience.title,
      experience.id,
      locale
    );
  };

  return (
    <div className="relative">
      <div className="translate-z-0 absolute top-1.5 z-10 flex w-full items-center justify-between px-1.5">
        <BBadge
          className="shadow-sm shadow-slate-500"
          variant="info"
          contrasted
        >
          {t('vehicleImmediatelyReservable')}
        </BBadge>
        <div
          className="transform cursor-pointer rounded-full duration-200 ease-in"
          onClick={() => toggleExperienceFavorite(experience.id)}
          tabIndex={-1}
          role="button"
        >
          <Favourited isCurrentFavorited={isCurrentFavorited} />
        </div>
      </div>
      <Link href={linkConstructor()}>
        <a
          className={classNames(styles.container, containerClass)}
          target={isMobile ? '' : '_blank'}
        >
          <div
            className={styles.image}
            onClick={sendAnalytics}
            role="presentation"
          >
            <Swiper
              modules={[Pagination]}
              pagination={{
                clickable: true,
                dynamicBullets: true,
                dynamicMainBullets: 5,
              }}
              spaceBetween={0}
              slidesPerView={1}
            >
              {experience.images.map((image, idx) => (
                <SwiperSlide key={`${image}-${idx}`}>
                  <BImage
                    src={image}
                    height={240}
                    width={300}
                    alt={`${experience.title}-idx`}
                    className="object-cover"
                    priority={isFirstImagePrioritized && idx === 0}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div>
            <div className="mb-1 flex items-start justify-between">
              <div className="text-sm">
                <span className="text-base font-medium">
                  {experience.title}
                </span>
              </div>
              <div className="flex shrink-0 items-center">
                <RatingStar
                  commentCount={experience.reviewCount || 0}
                  rating={experience.overallRating || 0}
                  size={16}
                  small
                />
              </div>
            </div>
            <div className="text-sm text-slate-500">
              Tur süresi: {hour ? `${hour} ${t('experience.hour')} ` : ''}
              {minute ? `${minute} ${t('experience.minute')} ` : ''}
            </div>
            {!experience.sessionSummary?.price ? null : (
              <div>
                <CurrencyText
                  className="text-lg"
                  price={experience.sessionSummary.price.amount}
                  currency={experience.sessionSummary.price.currency}
                />{' '}
                <span className="text-lg text-slate-500">
                  /{t(`units.headCount`)}
                </span>
              </div>
            )}
          </div>
        </a>
      </Link>
    </div>
  );
};

export default ExperienceCard;
