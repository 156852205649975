import ResponsiveCarousel from 'components/common/ResponsiveCarousel';
import ExperienceCard from 'components/Experience/ExperienceCard';
import { useLocale } from 'hooks/useLocale';
import useTranslation from 'next-translate/useTranslation';
import { FunctionComponent, useMemo } from 'react';
import { linkService } from 'services/LinkService';
import { ExperienceSummary } from 'types/experience/experience-summary.type';
import HomeSectionTitle from './HomeSectionTitle';

interface HomeExperiencesSectionProps {
  experiences: ExperienceSummary[];
}

const HomeExperiencesSection: FunctionComponent<
  HomeExperiencesSectionProps
> = ({ experiences }) => {
  const { t } = useTranslation('home');
  const { locale } = useLocale();

  const actionLink = useMemo(() => {
    const category = linkService.categoryLinkTranslations.find(
      (a) => a.tr === 'tekne-turu'
    )?.[locale];
    return linkService.generateCategoryLink({ category, locale });
  }, []);

  return experiences?.length > 0 ? (
    <section className="mt-16">
      <HomeSectionTitle
        title={t('experiencesSection.title')}
        description={t('experiencesSection.description')}
        href={actionLink}
      />
      <ResponsiveCarousel className="mt-10">
        {experiences?.map((experience, idx) => (
          <ExperienceCard experience={experience} key={`experience-${idx}`} />
        ))}
      </ResponsiveCarousel>
    </section>
  ) : null;
};

export default HomeExperiencesSection;
