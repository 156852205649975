import { dateService } from 'services/DateService';

export function getExperienceDurationByTime(duration: number) {
  const dayjs = dateService.getDayjs();
  const d = dayjs.duration(duration, 'm');
  return {
    hour: d.hours(),
    minute: d.minutes(),
  };
}
